<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span>订单管理</span>
      </div>
      <el-form :inline="true" :model="searchForm" ref="searchRef">
        <el-form-item label="订单号" prop="orderNum">
          <el-input
            v-model="searchForm.orderNum"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="下单时间" prop="time">
          <el-date-picker
            v-model="searchForm.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            @change="timeChange()"
          />
        </el-form-item>
        <el-form-item label="买家账号" prop="userPhone">
          <el-input
            v-model="searchForm.userPhone"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="服务人" prop="serveUser">
          <el-input
            v-model="searchForm.serveUser"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select
            v-model="searchForm.orderState"
            placeholder="请选择"
            clearable
          >
            <el-option label="全部" value=""> </el-option>
            <el-option label="待付款" value="0"> </el-option>
            <el-option label="待预约" value="10"> </el-option>
            <el-option label="服务中" value="20"> </el-option>
            <el-option label="已完成" value="30"> </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="用户性别" prop="sex">
          <el-select v-model="searchForm.sex" placeholder="请选择" clearable>
            <el-option label="男" :value="1"> </el-option>
            <el-option label="女" :value="0"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户手机号" prop="phoneNumber">
          <el-input
            v-model="searchForm.phoneNumber"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item> -->

        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSubmit"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" @click="resetSearch('searchRef')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>

      <div class="form-box">
        <!-- <el-button plain type="primary" icon="el-icon-plus" @click="addMan"
          >新增</el-button
        > -->
        <el-button
          icon="el-icon-download"
          plain
          type="primary"
          @click="onBatchExport"
          >导出</el-button
        >
        <!-- <el-button icon="el-icon-upload2" plain type="primary" @click="add"
          >导入</el-button
        > -->
      </div>
    </el-card>
    <el-card shadow="never" class="data-list">
      <div slot="header" class="clearfix">
        <span>用户列表</span>
      </div>
      <div class="table-box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="55" fixed> </el-table-column> -->
          <!-- <el-table-column prop="headImage" label="用户头像" width="90" fixed>
            <template slot-scope="scope">
              <img
                class="bgimg"
                :src="scope.row.userPhoto ? scope.row.userPhoto : img1"
                alt=""
              />
            </template>
          </el-table-column> -->
          <el-table-column prop="orderNum" label="订单号"> </el-table-column>
          <el-table-column prop="orderState" label="订单状态">
            <template slot-scope="scope">
              <span>
                {{ map[scope.row.orderState] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="orderTime" label="下单时间"> </el-table-column>
          <el-table-column prop="payType" label="付款方式">
            <!-- <template slot-scope="scope">
              <span>
                {{
                  scope.row.userSex == 1
                    ? '男'
                    : scope.row.userSex == 0
                    ? '女'
                    : '未知'
                }}
              </span>
            </template> -->
          </el-table-column>
          <el-table-column prop="orderPrice" label="订单金额">
          </el-table-column>
          <el-table-column prop="userPhone" label="买家账号"> </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="open(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
          <!-- <el-table-column prop="userIdCard" label="用户身份证" min-width="130">
          </el-table-column> -->
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          :hide-on-single-page="total < pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[6, 12, 24, 36, 100, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import table2excel from 'js-table2excel'
import * as orderApi from '@/api/order'
export default {
  data() {
    return {
      searchForm: {
        time: [],
        orderState: '',
        orderNum: '',
        startTime: '',
        endTime: '',
        userPhone: '',
        serveUser: '',
      },
      currentPage: 1,
      pageSize: 6,
      total: null,
      input: '',
      tableData: [],

      img1: require('/src/assets/images/user.png'),
      dialogVisible: false,
      roleId: '',
      roleDataoption: [],
      map: {
        '': '全部',
        0: '待付款',
        10: '待预约',
        20: '服务中',
        30: '已完成',
        40: '退订',
      },
    }
  },

  methods: {
    timeChange() {
      this.searchForm.startTime = this.searchForm.time[0]
        ? this.searchForm.time[0]
        : ''
      this.searchForm.endTime = this.searchForm.time[1]
        ? this.searchForm.time[1]
        : ''
    },
    addMan() {
      // this.showuser = true;
      // this.$nextTick(() => {
      this.$refs.adduser.init('新增')
      // });
    },
    editUser(val) {
      this.$refs.adduser.init('修改', val)
    },

    // async onDelete(id) {
    //   let params = {
    //     code: "",
    //     data: id,
    //     time: "",
    //   };
    //   let { data } = await this.$base.postAjax(
    //     "/mallUser/CustomerMan/deleteCustomerS",
    //     params
    //   );
    //   if (data.code == 200) {
    //     this.$message.success("删除成功");
    //     this.onSubmit();
    //   }
    // },
    handleSelectionChange() {},
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getData()
    },
    //重置
    resetSearch(formName) {
      this.$refs[formName].resetFields()
      this.searchForm.startTime = ''
      this.searchForm.endTime = ''
      this.onSubmit()
    },
    //搜索
    onSubmit() {
      this.currentPage = 1
      this.getData()
    },
    //获取数据
    async getData() {
      let params = {
        // data: {
        ...this.searchForm,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        // },
      }
      let res = await orderApi.orderList(params)
      if (res.code == 200) {
        this.tableData = res.data.records
        this.total = res.data.total
      } else {
        this.$message.warning(res.msg)
      }
    },
    //监听排序
    sortChange() {},
    //表格数据
    ValueFormatter(a, b, c) {
      if (c && c != null && c != undefined && c != 'null' && c != 'undefined') {
        return c
      } else {
        return '-'
      }
    },
    bangdingrole(row) {
      this.roleId = row.roleId || ''
      this.dialogVisible = true
      let params = {
        data: {
          pageNum: 1,
          pageSize: 999,
        },
      }
      userApi.roleList(params).then((res) => {
        this.roleDataoption = res.data.data
      })
    },
    colse() {
      this.roleId = ''
      this.dialogVisible = false
    },
    open(row) {
      this.$router.push({
        path: '/orderForm',
        query: {
          orderNum: row.orderNum,
        },
      })
    },
    onBatchExport() {
      orderApi.exportOrder(this.searchForm).then((res) => {
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(res)
        link.download = '订单列表.xlsx'
        document.body.appendChild(link)
        link.click()
      })
      // let { code, data, msg } = await orderApi.exportOrder(this.searchForm)
      // if (code == 200) {
      // let tableLists = data
      // const column = [
      //   { title: '订单号', key: 'orderNum', type: 'text' },
      //   { title: '订单状态', key: 'orderState', type: 'text' },
      //   { title: '下单时间', key: 'orderTime', type: 'text' },
      //   { title: '付款方式', key: 'payType', type: 'text' },
      //   { title: '订单金额', key: 'orderPrice', type: 'text' },
      //   { title: '买家账号', key: 'userPhone', type: 'text' },
      //   { title: '备注', key: 'remark', type: 'text' },
      // ]
      // // tableLists.map((item) => {
      // //   item["_merchantCode"] = '`'+item.merchantCode
      // // });
      // table2excel(column, tableLists, '订单列表')
      // } else {
      //   this.$message.error(msg)
      // }
    },
  },
  created() {
    this.onSubmit()
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>
.bgimg {
  width: 60px;
  height: 60px;
}
</style>
